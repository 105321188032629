import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Geolocation } from '@capacitor/geolocation';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from "src/app/services/toast/toast.service";
import { PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  currentLocation: string = 'Fetching location...';
  autocompleteService: any; // Autocomplete service instance
  filteredLocations: any[] = [];
  private locationsSubject: Subject<any[]> = new Subject(); // Observable to emit filtered locations
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private router: Router) {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

   // Initialize Push Notifications
   async initPushNotifications() {
    // Request permission for notifications
    const permission = await PushNotifications.requestPermissions();
    if (permission.receive === 'granted') {
      // Register the device for push notifications
      PushNotifications.register();

      // Save token when registration is successful
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration success:', token.value);
        localStorage.setItem('deviceToken', token.value); // Save token locally
      });

      // Handle registration errors
      PushNotifications.addListener('registrationError', (error) => {
        console.log('Push registration error:', error);
      });

      // Handle push notifications received (foreground)
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received:', notification);

        // alert('New Notification: ' + notification.body);
        this.toastService.presentToast('Success', notification.body, 'bottom', 'success', 2000);
      });

      // Handle push notification action when tapped (background/killed)
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Notification action performed:', notification);

          // Redirect to the order summary page
          const orderId = notification.notification.data.orderId;
          if (orderId) {
            this.router.navigate(['/order-details', orderId]); // Navigate with orderId
          } else {
            this.router.navigate(['/home']); // Default navigation
          }
        }
      );
    } else {
      console.log('Push notifications permission not granted');
    }
  }

  async getCurrentLocation() {
    try {
      const position = await Geolocation.getCurrentPosition();
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      this.reverseGeocode(lat, lng); // Convert coordinates into an address
    } catch (error) {
      console.error('Error getting location', error);
      this.currentLocation = 'Unable to fetch location';
    }
  }

  reverseGeocode(lat: number, lng: number) {
    console.log(lat)
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.firebase.apiKey}`;
    this.http.get(geocodeUrl).subscribe((response: any) => {
      if (response.results && response.results.length > 0) {
        this.currentLocation = response.results[0].formatted_address;
      } else {
        this.currentLocation = 'Address not found';
      }
      console.log(this.currentLocation)
    }, (error) => {
      console.error('Error fetching geolocation:', error);
      this.currentLocation = 'Unable to fetch address';
    });
  }

  searchLocation(query: string): void {
    if (!this.autocompleteService) {
      console.error('Autocomplete service is not initialized');
      return;
    }

    const options = {
      input: query,
      location: new google.maps.LatLng(42.3149, -83.0364), // Default to Windsor
      radius: 10000,
      componentRestrictions: { country: 'CA' },
    };

    this.autocompleteService.getPlacePredictions(options, (predictions: any[], status: string) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        // Emit the predictions to the subscribers
        this.locationsSubject.next(predictions.map(prediction => ({
          display_name: prediction.description,
          place_id: prediction.place_id,
        })));
      } else {
        console.error('Error fetching predictions:', status);
        // Emit an empty array in case of error
        this.locationsSubject.next([]);
      }
    });
  }

  // Expose the observable for filtered locations
  getFilteredLocations(): Observable<any[]> {
    return this.locationsSubject.asObservable();
  }

  // Fetch place details using the placeId after searching
  getPlaceDetails(placeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Use a null or empty div to initialize PlacesService without a map
      const placesService = new google.maps.places.PlacesService(document.createElement('div')); 

      placesService.getDetails({
        placeId: placeId
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placeDetails = {
            name: place.name,
            address: place.name+' '+place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            place_id: placeId,
            rating: place.rating,  // Optional: Rating of the place
          };
          resolve(placeDetails);
        } else {
          reject('Place details not found.');
        }
      });
    });
  }

}