import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-terms-modal",
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Terms and Conditions</ion-title>
        <ion-buttons slot="end">
          <ion-icon name="close-outline" (click)="closeModal()"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content padding>
      <div *ngIf="loading">Loading...</div>
      <div *ngIf="!loading" [innerHTML]="termsContent"></div>
    </ion-content>
  `,
})
export class TermsModalComponent implements OnInit {
  termsContent: string = ""; // Store terms and conditions content
  loading: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.fetchTermsAndConditions();
  }

  fetchTermsAndConditions() {
    this.authService.getTermsContent().subscribe(
      (data) => {
        this.termsContent = data.terms;
        this.loading = false;
      },
      (error) => {
        console.error("Error fetching terms:", error);
        this.termsContent = "Failed to load terms and conditions.";
        this.loading = false;
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}