import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { CartService } from './services/cart.service';
import { Router } from '@angular/router';
import { GoogleService } from './services/google.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  cartItemCount: number = 0;
  constructor(
    private platform: Platform,
    private cartService: CartService,
    private router: Router,
    private googleService:GoogleService
  ) {
    this.initializeApp();

  }

  // Initialize app
  initializeApp() {

    // Wait until platform is ready
    this.platform.ready().then(async () => {

      // If we're on a mobile platform (iOS / Android), not web
      if (Capacitor.getPlatform() !== 'web') {

        // Set StatusBar style (dark / light)
        await StatusBar.setStyle({ style: Style.Light });
        this.googleService.initPushNotifications();
      }

      // ...
      // do some more config and setup if necessary
      // ...

      // Fake timeout since we do not load any data
      setTimeout(async () => {

        // Hide SplashScreen
        await SplashScreen.hide();
      }, 2000);
    });

    // Subscribe to the cart service to get the current cart count
    this.cartService.tgetCartItems().subscribe((cartItems) => {
      this.cartItemCount = cartItems.length;
    });

    //const isFirstTime = localStorage.getItem('isFirstTime');

    // if (isFirstTime === null) {
    //   this.router.navigateByUrl('/welcome');
    //   localStorage.setItem('isFirstTime', 'false');
    // } 
  }
}